
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./comment.css"
import  { addReport } from "../../services/report.service";

export default function CommentItem({ comment }) {

    const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
    const [fecha, setFecha] = useState([]);
    const [denunciar, setDenunciar] = useState(false);
    const [denunciaOpcion, setDenunciaOpcion] = useState("explicito");

    let { id } = useParams();

    useEffect(() => {
        setFecha(new Date(comment.fecha).toLocaleString());
    }, []);

    const denunciarlo = () => {
        if (denunciar === false)
            setDenunciar(true);
        else setDenunciar(false);
    }
    const pathReport = `${process.env.REACT_APP_API_AWS}/reports`;
    const handleSubmit = async (e) => {
        e.preventDefault();
        const report = {
            id_report: comment._id,
            type: "comment",
            content: comment.text,
            option: denunciaOpcion,
            reported: comment.author,
            reporter: JSON.parse(localStorage.getItem("email"))
        }
        const res = await addReport(report,token);
        if(res){
            window.location = `/tournaments/${id}`;
        }
    };
    return (
        <div className="comment">
            <div className="comment_autor">
                <p className="text">{comment.author}</p>
                <p className="text">&nbsp;{fecha}</p>
                <div className="denuncia_container">
                    <button onClick={denunciarlo}>...</button>
                    {denunciar && <form action={pathReport} onSubmit={handleSubmit}>
                        <label htmlFor="">Reportar</label><br />
                        <select type="text" defaultValue={"explicito"} name={denunciaOpcion} onChange={(e) => {setDenunciaOpcion(e.target.value)}}>
                            <option value="explicito" selected>Contenido Explícito</option>
                            <option value="indebida">Conducta Indebida</option>
                        </select>
                        <button type="submit">Enviar</button>
                    </form>}
                </div>

            </div>
            <br />
            <p>{comment.text}</p>
            <br />
        </div>
    );
}

