
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./playeritem.css"

export default function PlayerItem({ position, jugador }) {

    return (
        <Link to={`/profile/${jugador._id}`}>
            <div className="player_item">
                <img className="player_item_foto" src={jugador.foto} alt={jugador.foto} />
                <h4>{jugador.id_activision}</h4>
            </div>
        </Link>
    );
}

