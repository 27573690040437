
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getPlayerById, updateFoto } from "../../../services/player.service";
import { getTeamsByEmail } from "../../../services/team.service"
import './playeritemdetail.css'
import TeamItemModel from "./../../teams/item/TeamItemModel";
import Chat from "../../chats/Chat";

export default function ProfileItemDetail({ }) {
    const [token, setToken] = useState([]);
    const [email, setEmail] = useState([]);
    const [teamsPlayer, setTeamsPlayer] = useState([]);
    const [fotoPerfil, setFotoPerfil] = useState([]);
    const [foto, setFoto] = useState([]);
    const [isPartner, setIsPartner] = useState(false);
    const [idActivision, setIdActivision] = useState([0]);

    let { id } = useParams();

    useEffect(() => {
        setEmail(JSON.parse(localStorage.getItem("email")));
        setToken(JSON.parse(localStorage.getItem("token")));
        if (email != null) {
            fetchPlayer(id);
        }
    }, []);
    const fetchPlayer = async (id) => { //Traemos los nombres de los equipos
        const email = JSON.parse(localStorage.getItem("email"));
        try {
            const player = await getPlayerById(id);
            setFotoPerfil(`${player.foto}`)
            setIdActivision(player.id_activision);

            const teams = await getTeamsByEmail(player.email)
            setTeamsPlayer(teams);
            if (player.email === email) {
                setIsPartner(true);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const pathPlayers = `${process.env.REACT_APP_API_AWS}/players/profile/${id}`;
    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await updateFoto(token, id, foto);
        if (res.ok) {
            window.location = `/profile/${id}`;
        }
    };

    return (
        <div className="team_detail">
            <div className="list_section">
                <aside className="asideLeft">

                </aside>
                <div className="player_detail_section border_section">
                    <div className="player_detail_nav">
                        <h3>{idActivision}</h3>
                    </div>
                    <img src={fotoPerfil} alt={fotoPerfil} className="fotoPlayer" /><br />
                    {isPartner && <form id="formulario" className="player_detail_formulario" action={pathPlayers} method='POST' onSubmit={handleSubmit}>
                        <p> Max Size 1MB </p>
                        <input className="btn_detail_form_input_file" type="file" name="foto" onChange={(e) => { setFoto(e.target.files[0]) }} />
                        <input type='submit' value='Actualizar Foto' className="btn_section btn_gold" />
                    </form>}
                    <div className="player_detail_teams">
                        <h4>Equipos:</h4><br />
                        {teamsPlayer.map((team, i) => {
                            return <TeamItemModel key={i} position={i + 1} team={team} />;
                        })}
                        <br />
                    </div>
                </div>
                <aside className="asideRight">
                    <Chat />
                </aside>
            </div>
        </div>

    );
}

