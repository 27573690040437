
import "./loginitem.css"
import "./../forms/forms.css"
import "./logingoogle.css"
import React, { useEffect, useState } from "react";
import { addPlayer, loginPlayer } from "../../services/player.service";

export default function LoginGoogle({ }) {

  const [showTeam, setShowTeam] = useState(true);
  const [showEmail, setShowEmail] = useState(false);
  const [contraseñaOK, setContraseñaOK] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nick, setNick] = useState("");

  useEffect(() => {

  }, []);


  function activateTeam(e) {
    setShowTeam(true)
  }
  function activateTournament(e) {
    setShowTeam(false)
  }
  const checkPassword = (e) => {
    if (!e.target.checkValidity()) {
      e.target.setCustomValidity("Como mínimo 5 caracteres,1 mayúscula, 1 minúscula y 1 número");
      e.target.reportValidity();
      e.target.focus();
    }
  }
  const checkRepitePass = (e) => {
    if (e.target.value !== password) {
      e.target.setCustomValidity("La contraseña no coincide!!");
      e.target.reportValidity();
      e.target.focus();
    }
  }
  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    const dataLogin = {
      profileAndroid: {
        email: email,
        password: password
      }
    }
    const player = await loginPlayer(dataLogin);

    if (player.token) {
      const data = {
        profileAndroid: player
      }
      localStorage.setItem("userAndroid", JSON.stringify(data));
      localStorage.setItem("token", JSON.stringify(player.token));
      window.location = '/';
    } else {
      localStorage.setItem("userAndroid", null);
      localStorage.setItem("token", null);
      setContraseñaOK(false);
    }
  };
  const handleSubmitRegistro = async (e) => {
    e.preventDefault();
    const data = {
      profileAndroid: {
        email: email,
        password: password,
        nick: nick
      }
    }
    await fetchPlayers(data);
  };

  const fetchPlayers = async (data) => {
    try {
      let player = await addPlayer(data);
      if (player) {
        localStorage.setItem("userAndroid", JSON.stringify(data));
        if (player !== "No autorizado")
          window.location = '/';
        else setShowEmail(true);
      }
    } catch (e) {
      localStorage.setItem("userAndroid", null)
      //console.log(e);
    }
  };
  const pathPlayers = `${process.env.REACT_APP_API_AWS}/players`;
  return (

    <div className="login_item">
      {showTeam && <form action={pathPlayers} onSubmit={handleSubmitLogin} className="form_player">
        <h2 className="gold">Login</h2>
        <div className="login_item_row">
          <label>Email:</label><br />
          <input type="email" name={email} placeholder="example@gmail.com" required onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="login_item_row">
          <label>Password:</label><br />
          <input type="password" name={password} placeholder="password" required onChange={(e) => {
            setPassword(e.target.value);
            e.target.setCustomValidity("");
          }} onBlur={checkPassword} minLength="5" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{5,}$" />
        </div>
        {!contraseñaOK && <p className="login_item_nickocupado">El email y/o la contraseña no es correcta</p>}
        <br />
        <button type="submit" className="btn_section btn_gold">Aceptar</button>
        <br />
        <p onClick={activateTournament}><b>¿Aún no tienes una cuenta?</b></p>
      </form>}
      {!showTeam && <form action={pathPlayers} onSubmit={handleSubmitRegistro} >
        <h2 className="gold">Registro</h2>
        <div className="login_item_row">
          <label>Email:</label><br />
          <input type="email" name={email} placeholder="example@gmail.com" pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])" required onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="login_item_row">
          <label>Password:</label><br />
          <input type="password" name={password} placeholder="password" required onChange={(e) => {
            setPassword(e.target.value);
            e.target.setCustomValidity("");
          }} onBlur={checkPassword} minLength="5" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{5,}$" />
        </div>
        <div className="login_item_row">
          <label>Repite Password:</label><br />
          <input type="password" placeholder="repite password" required onChange={(e) => {
            e.target.setCustomValidity("");
          }} onBlur={checkRepitePass} minLength="5" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{5,}$" />
        </div>
        <div className="login_item_row">
          <label>Nick:</label><br />
          <input type="text" name={email} required onChange={(e) => setNick(e.target.value)} />
        </div><br />
        {showEmail && <p className="login_item_nickocupado">El email ya está en uso</p>}
        <br />
        <button type="submit" className="btn_section btn_gold">Aceptar</button>
        <br />
        <p onClick={activateTeam}><b>¿Ya tienes una cuenta?</b></p>
      </form>}
    </div>

  );
}

