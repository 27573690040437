import { trackPromise } from 'react-promise-tracker';
const pathTournaments = `${process.env.REACT_APP_API_AWS}/tournaments`;


const getTournaments = async (page) => {
  try {
    const response = await trackPromise(fetch(`${pathTournaments}/page/${page}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getTournamentById = async (id) => {
  try {
    const response = await trackPromise(fetch(`${pathTournaments}/${id}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getTournamentByName = async (id) => {
  try {
    const response = await trackPromise(fetch(`${pathTournaments}/name/${id}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getComentsById = async (id) => {
  try {
    const response = await trackPromise(fetch(`${pathTournaments}/comments/${id}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const addTournament = async (token, tournament) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    let res = await trackPromise(fetch(`${pathTournaments}`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        tournament: tournament
      }),
    }));
    return res;
  } catch (err) {
    console.log(err);
  }
}
const joinTournament = async (token, id, team) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    let res = await trackPromise(fetch(`${pathTournaments}/${id}`,{
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        team: team
      }),
    }));
    return res;
  } catch (err) {
    console.log(err);
  }
}
const addComentsById = async (id, token, author, text) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    let res = await trackPromise(fetch(`${pathTournaments}/comments/${id}`,{
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        author: author,
        text: text
      }),
    }));
    return res;
  } catch (err) {
    console.log(err);
  }
};
const checkDealerCodeByName = async (id, joinCode) => {
  try {
    const response = await trackPromise(fetch(`${pathTournaments}/check/${id}/${joinCode}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const addDealerById = async (token, id , nick) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    const response = await trackPromise(fetch(`${pathTournaments}/dealer/${id}`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
            nick: nick
        }),
    }));
    return response;
} catch (err) {
    console.log(err);
}
};


export { getTournaments, getTournamentById, getTournamentByName, addTournament, joinTournament, checkDealerCodeByName, getComentsById, addComentsById, addDealerById };
