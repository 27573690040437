
import React, { useEffect, useState } from "react";

export default function Politica({ }) {

    const [isAccept, setIsAccept] = useState(false);

    useEffect(() => {
        const contenido = JSON.parse(localStorage.getItem("contenido"));
        if(contenido != null){
            if(contenido){
                setIsAccept(true);
            }else setIsAccept(false);
        }
    }, []);

    const accept = () => {
        setIsAccept(true);
        JSON.stringify(localStorage.setItem("contenido",true));
    }
    return (
        <div className="politica-contenido">
            {!isAccept && <div className="politica-contenido-text">
                <h2>Normas de utilización del servicio</h2>
                <p>El Usuario se obliga a utilizar el Servicio conforme a lo establecido en la ley, la moral, el orden público y los presentes Términos y Condiciones. Asimismo, se obliga a hacer un uso adecuado del Servicio y a no emplearlo para realizar actividades ilícitas o constitutivas de delito, que atenten contra los derechos de terceros o que infrinjan cualquier norma del ordenamiento jurídico.</p><br />
                <p>El Usuario se obliga a no transmitir, introducir, difundir y/o poner a disposición de terceros, cualquier tipo de material e información (productos, objetos, datos, contenidos, mensajes, dibujos, archivos de sonido e imagen, fotografías, software, etc.) que sean contrarios a la ley, la moral, el orden público y los presentes Términos y Condiciones. A título enunciativo y en ningún caso limitativo o excluyente, el Usuario se compromete a:</p><br />
                <p>No introducir o difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico, de apología del terrorismo, conflicto armado, incitación al odio o que atenten contra los derechos humanos.</p><br />
                <p>No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que atente contra los derechos fundamentales y las libertades públicas reconocidos constitucionalmente y en los tratados internacionales.</p><br />
                <p>No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que constituya publicidad ilícita o desleal.</p><br />
                <p>No transmitir publicidad no solicitada o autorizada, material publicitario, “correo basura”, “cartas en cadena”, “estructuras piramidales”, o cualquier otra forma de solicitación, excepto en aquellas áreas (tales como espacios comerciales) que hayan sido exclusivamente concebidas para ello.</p><br />
                <p>No introducir o difundir cualquier información y contenidos falsos, engañosos, ambiguos o inexactos de forma que induzca o pueda inducir a error a los receptores de la información.</p><br />
                <p>No suplantar a otros Usuarios del Servicio ni transmitir los datos de acceso a la cuenta ni la contraseña a un tercero sin el consentimiento de Wallapop.</p><br />
                <p>No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido sin autorización de los titulares de los derechos de propiedad intelectual e industrial que puedan recaer sobre tal información, elemento o contenido.</p><br />
                <p>No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que suponga una violación del secreto de las comunicaciones y la legislación de datos de carácter personal.</p><br />
                <p>No difundir, transmitir o poner a disposición de terceros fotografías ni cualquier representación o imagen de personas menores de edad.</p><br />
                <p>No publicar anuncios de productos que requieran receta médica o que deban dispensarse bajo la supervisión de un médico (doctor, dentista, optometrista, óptico, farmacéutico o veterinario), así como los productos que puedan influir en la salud del individuo (que presenten contraindicaciones, interacciones, etc.) y perecederos de cualquier tipo.</p><br />
                <p>Disponer de autorización sanitaria de funcionamiento para publicar anuncios relacionados con cualquier actividad sanitaria (necesidad de presentar el número de colegiación o número de registro del centro).</p><br />
                <p>No crear valoraciones de transacciones no efectuadas.</p><br />
                <p>No reportar falsamente o incorrectamente de forma reiterada.</p><br />
                <button className="btn_section btn_gold" onClick={accept}>Aceptar</button>
            </div>}
        </div>
    );
}

