import './tournamentItem.css'
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import { variantsMotion } from '../../utils/const';


export default function TournamentItem({ position, tournament }) {
  const [colorEquipo, setColorEquipo] = useState([]);
  const [colorFecha, setColorFecha] = useState([]);
  const [allowJoin, setAllowJoin] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [idTorneo, setIdTorneo] = useState([]);
  const [finInscripcion, setFinInscripcion] = useState([]);
  const [maxteams, setMaxteams] = useState([]);
  const [equiposApuntados, setEquiposApuntados] = useState([]);

  useEffect(() => {
    setInterval(() => { cuentaAtras() }, 1000);
    setEquiposApuntados(tournament.teams);
    setMaxteams(tournament.maxteams);
    setIdTorneo(tournament._id)
    colorTeams(tournament.teams, tournament.maxteams);
    checkColorFecha(tournament.finInscripcion);
    setIsFinished(tournament.isFinished);
  }, []);

  const cuentaAtras = () => {
    const milisPorSec = 1000;
    const milisPorMin = milisPorSec * 60;
    const milisPorHor = milisPorMin * 60;
    const milisPorDia = milisPorHor * 24
    const diferencia = new Date(tournament.finInscripcion) - new Date();
    const DIAS = Math.floor(diferencia / milisPorDia);
    const HORAS = Math.floor((diferencia % milisPorDia) / milisPorHor);
    const MINUTOS = Math.floor((diferencia % milisPorHor) / milisPorMin);
    const SEGUNDOS = Math.floor((diferencia % milisPorMin) / milisPorSec);

    if (diferencia > 0)
      setFinInscripcion(`${DIAS}d ${HORAS}h ${MINUTOS}m ${SEGUNDOS}s`)
    else setFinInscripcion(new Date(tournament.finInscripcion).toLocaleDateString());
  }
  const colorTeams = (apuntados, maxTeams) => {
    if ((apuntados.length >= 0)) {
      setColorEquipo("#0AE800"); //Verde
    }
    if ((apuntados.length >= maxTeams * 0.5)) {
      setColorEquipo("#0074E8"); //Azul
    }
    if ((apuntados.length >= maxTeams * 0.75)) {
      setColorEquipo("#E81C00"); //Rojo
    }
  }
  const checkColorFecha = (fechaInscripcion) => {
    const actual = new Date();
    const fechaElegida = new Date(fechaInscripcion);
    if (fechaElegida.getTime() <= actual.getTime()) {
      setColorFecha("#E81C00"); //Rojo
      setAllowJoin(false)
    } else {
      setColorFecha("#0AE800"); //Verde
      setAllowJoin(true)
    }
  }


  return (

    <motion.div
      className="tournament_item"
      custom={{ delay: (position * 0.05) }}
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={variantsMotion}
      layoutId={position}
    >
      <div className='tournament_item_box'>
        <Link to={`/tournaments/${tournament._id}`}>
          <div className="tournament_item_container">
            <div>
              <img src={tournament.url} alt={tournament.mapa} />
              <div>
                <p className='permanent gold nombreMapa'>{tournament.mapa}</p>
              </div>
            </div>
            <div className='tournament_item_center'>
              <div className='tournament_item_right'>
                <h4 className='permanent gold'>{tournament.nombre}</h4>
                <div className="num-teams-item">
                  <h5 className="text">Equipos:</h5>
                  <h3 style={{ color: colorEquipo }}><b>{equiposApuntados.length}:{maxteams}</b></h3>
                </div>
              </div>
              <div className='tournament_item_date'>
                <h5 className="text">Inscripción:</h5>
                <h5 className='' style={{ color: colorFecha }}>{finInscripcion}</h5>
              </div>
              <div className='tournament_item_join'>
                <h5>- {tournament.partida} -</h5>
                {!isFinished
                  ? <div>
                    {allowJoin
                      ? <Link to={`/tournaments/join/${idTorneo}`} ><div><button className='btn_section btn_green' >Unirme</button></div></Link>
                      : <div><button className='btn_section btn_red' >Unirme</button></div>
                    }
                  </div>
                  : <div><button className='btn_section btn_red' >Finalizado</button></div>
                }

              </div>
            </div>
          </div>
        </Link>
      </div>
    </motion.div>


  );
}

