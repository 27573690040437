import { useState } from "react";
import "./forms.css"
import FormTeam from "./items/FormTeam";
import FormTournament from "./items/FormTournament"

export default function Forms() {

    const [showTeam, setShowTeam] = useState(true);

    function activateTeam(e) {
        setShowTeam(true)
    }
    function activateTournament(e) {
        setShowTeam(false)
    }

    return (
        <div className="forms">
            <div className="form_buttons">
                <button className="btn_section btn_green" onClick={activateTeam}>Equipo</button>
                <button className="btn_section btn_green" onClick={activateTournament}>Torneo</button>
            </div>
            {showTeam && <FormTeam />}
            {!showTeam && <FormTournament />}
        </div>

    );
}
