
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTournamentById, checkDealerCodeByName, addDealerById } from "../../services/tournament.service";
import "./loginitem.css"
import "./joinTeam.css"

export default function DealerTournament({ }) {
    const [token, setToken] = useState([]);
    const [nick, setNick] = useState([]);
    const [joinCode, setJoinCode] = useState([]);
    const [isValid, setIsValid] = useState(true);
    const [name, setName] = useState([]);
    const [idTorneo, setIdTorneo] = useState([]);

    let { id } = useParams();
    let { code } = useParams();

    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem("token")));
        setNick(JSON.parse(localStorage.getItem("nick")));
        setJoinCode(code);
        setIdTorneo(id);
        fetchTournament();
    }, []);
    const fetchTournament = async () => {
        const torneo = await getTournamentById(id);
        setName(torneo.nombre);
    }
    const join = async () => {
        const check = await checkDealerCodeByName(idTorneo, joinCode);
        if (check) {
            await addDealer();
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }
    const addDealer = async () => {
        const res = await addDealerById(token, id, nick);
        if (res.ok) {
            window.location = `/tournaments/${id}`;
        }
    };
    return (
        <div className="joinTeam">
            <div className="joinTeam_container">
                <h3>{name}</h3>
                <h5>Introduce el código de acceso para ser organizador.</h5>
                {!isValid && <p className="login_item_nickocupado">¡El Código no es válido!</p>}
                <br />
                <input type="text" name={joinCode} value={joinCode} placeholder="Código" onChange={(e) => { setJoinCode(e.target.value) }} />
                <button onClick={join} className="btn_section btn_gold">Ser Organizador</button>
            </div><br />
        </div>
    );
}