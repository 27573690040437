
import React, { useEffect } from "react";
import "./chat.css";

export default function ChatItem({ parrafo }) {

    useEffect(() => {

    }, []);

    return (
        <div className="chat_item">
            <p className="chat_author">{parrafo.nick}: <span className="chat_message">{parrafo.message}</span></p>
            
        </div>
    );
}

