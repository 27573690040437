import "./match.css";
import React, { useEffect, useState } from "react";
import { getTeamByName } from "./../../../services/team.service"

export default function MatchItem({ position, team, match }) {

  const [teamName, setTeamName] = useState([]);
  const [teamItem, setTeamItem] = useState([]);
  const [points, setPoints] = useState([]);
  const [foto, setFoto] = useState([]);
  const [fecha, setFecha] = useState([]);

  useEffect(() => {
    setTeamName(team)
    setFecha(new Date(match.datetime).toLocaleString());
    setPoints(match.points);
    setFoto(match.url);
    fetchTeam(team);
  }, []);

  const fetchTeam = async (team) => {
    try {
      const teamItemDB = await getTeamByName(team);
      setTeamItem(teamItemDB)
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="match_item">
      <div className="match_item_team">
        <img src={`${teamItem.image}`} alt={teamName} className="match_item_team_logo" />
        <h4 className="match_team_name">{teamName}</h4>
      </div>
      <h3 className="match_puntuacion" >{points}</h3>
      <h5 className="match_fecha">{fecha}</h5>
      <a href={`${foto}`}><img src={`${foto}`} alt={teamName} className="match_item_foto" /></a>
    </div>
  );
}
