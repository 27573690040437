import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./formmatch.css";
import { getTournamentById } from "../../../services/tournament.service";
import { getTeamByName } from "../../../services/team.service";
import { getPlayersByTeam } from "../../../services/player.service";
import { updateFotoMatch, saveMatch, addMatchTournament } from "../../../services/match.service";

export default function FormMatch() {
    const [datetime, setDatetime] = useState([]);
    const [teamPosition, setTeamPosition] = useState([]);
    const [tournament, setTournament] = useState([]);
    const [fechaComienzo, setFechaComienzo] = useState([]);
    const [token, setToken] = useState([]);
    const [equiposApuntados, setEquiposApuntados] = useState([]);
    const [equipoElegido, setEquipoElegido] = useState([]);
    const [jugadoresTeam, setJugadoresTeam] = useState([]);
    const [numMatchesTeams, setNumMatchesTeams] = useState([]);
    const [pointsBaja, setPointsBaja] = useState([]);
    const [primero, setPrimero] = useState([]);
    const [segundo, setSegundo] = useState([]);
    const [tercero, setTercero] = useState([]);
    const [numPartidas, setNumPartidas] = useState([]);
    const [player1, setPlayer1] = useState([]);
    const [player2, setPlayer2] = useState([]);
    const [player3, setPlayer3] = useState([]);
    const [player4, setPlayer4] = useState([]);
    const [totalPoints, setTotalPoints] = useState([]);
    const [allowSend, setAllowSend] = useState(false);
    const [image, setImage] = useState([]);

    let { id } = useParams();

    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem("token")));
        fetchTeams(id)
        setTotalPoints(0)
        setTeamPosition(1)
        setNumMatchesTeams(1)
    }, []);

    document.addEventListener("DOMContentLoaded", (e) => {
        document.getElementById("formulario").addEventListener("change", calculaPuntuacion)
    })

    const fetchTeams = async (id) => {
        try {
            const tournament = await getTournamentById(id);
            setTournament(tournament);
            setNumPartidas(tournament.numPartidas);
            setFechaComienzo(new Date(tournament.fechaComienzo));
            setPointsBaja(tournament.reglas.pointsBaja);
            setPrimero(tournament.reglas.primero);
            setSegundo(tournament.reglas.segundo)
            setTercero(tournament.reglas.tercero)
            await fetchTeamsJoints(tournament.teams, tournament.numPartidas);
        } catch (e) {
            console.log(e);
        }
    };
    const fetchTeamsJoints = async (equiposApuntadosDB, numeroPartidas) => {
        try {
            let equipos = [];
            let equiposApuntadosDBsinTerminar = [];
            for (let equipoApuntadoDB of equiposApuntadosDB) { //Recorro los equipos apuntados
                if (equipoApuntadoDB.matches.length < Number(numeroPartidas)) { //Si el equipo no ha terminado sus partidas lo añadimos...
                    equiposApuntadosDBsinTerminar.push(equipoApuntadoDB);
                }
            }
            for (let equipoApuntadoDB of equiposApuntadosDBsinTerminar) { //Recorro los equipos apuntados sin terminar
                let jugadoresCompletosDB = [];
                const team = await getTeamByName(equipoApuntadoDB.name);
                const playersDB = await getPlayersByTeam(equipoApuntadoDB.name); //Me traigo los jugadors completos del equipo

                for (let playerApuntado of equipoApuntadoDB.players) { //Recorro los jugadores del equipo apuntado
                    for (let playerDB of playersDB) {
                        if (playerApuntado === playerDB.email) { //Si coinciden los emails.. añado el jugador completo
                            jugadoresCompletosDB.push(playerDB)
                        }
                    }
                }
                team.players = jugadoresCompletosDB;
                equipos.push(team)
            }
            setEquiposApuntados(equipos);
            setEquipoElegido(equiposApuntadosDBsinTerminar[0].name);
            setNumMatchesTeams(equiposApuntadosDBsinTerminar[0].matches.length + 1);
            findPlayersTeam(equipos, equipos[0].name);
        } catch (e) {
            console.log(e);
        }
    };
    const findPlayersTeam = (equipos, teamName) => {
        for (let equipoApuntado of equipos) {
            if (teamName === equipoApuntado.name) {
                setJugadoresTeam(equipoApuntado.players);
            }
        }
    }
    const findMatchesTeam = (equiposApuntados, teamName) => {
        for (let equipoApuntado of equiposApuntados) {
            if (teamName === equipoApuntado.name) {
                setNumMatchesTeams(equipoApuntado.matches.length + 1)
            }
        }
    }
    const sumaMuertes = (numMuertes, index) => {
        if (index === 0) {
            setPlayer1(Number(numMuertes));
        }
        if (index === 1) {
            setPlayer2(Number(numMuertes));
        }
        if (index === 2) {
            setPlayer3(Number(numMuertes));
        }
        if (index === 3) {
            setPlayer4(Number(numMuertes));
        }
    }
    const checkDate = (e) => {
        const actual = new Date();
        const fechaElegida = new Date(e.target.value);
        e.target.setCustomValidity("")
        if (e.target.checkValidity()) {
            if (fechaElegida.getTime() >= actual.getTime()) {
                e.target.setCustomValidity("La fecha es posterior al momento actual")
                e.target.reportValidity();
                e.target.focus()
            }
            if (fechaElegida.getTime() <= fechaComienzo.getTime()) {
                e.target.setCustomValidity("La fecha es anterior al " + fechaComienzo.toLocaleString())
                e.target.reportValidity();
                e.target.focus()
            }
        }
    }
    const calculaPuntuacion = () => {
        let numTotalMuertes = player1 + player2 + player3 + player3 + player4;
        let puntosPorMuertes = numTotalMuertes * pointsBaja;
        let resultadoMultiplicador = 0;
        if (Number(teamPosition) === 1) {
            resultadoMultiplicador = Number(puntosPorMuertes) * Number(primero);
        }
        if (Number(teamPosition) === 2) {
            resultadoMultiplicador = Number(puntosPorMuertes) * Number(segundo);
        }
        if (Number(teamPosition) === 3) {
            resultadoMultiplicador = Number(puntosPorMuertes) * Number(tercero);
        }
        if (Number(teamPosition) >= 4) {
            resultadoMultiplicador = Number(puntosPorMuertes);
        }
        setTotalPoints(resultadoMultiplicador);
        setAllowSend(true);
    }
    const pathMatches = `${process.env.REACT_APP_API_AWS}/matches`;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const res = await saveMatch(token, equipoElegido, totalPoints, datetime); //Guardo el match en su coleccion y recupero el id
        if (res.ok) {
            const match = await res.json();
            await addMatchTournament(token, id, match._id, equipoElegido, totalPoints, datetime); //Actualizo el torneo con su nuevo match
            const resFoto = await updateFotoMatch(image, token, match._id);
            if (resFoto.status === 200) {
                window.location = `/tournaments/`;
            }
        }
    };
    return (
        <div className="form_match_container border_section">
            <form id="formulario" action={pathMatches} className="form_match" onSubmit={handleSubmit} >
                <br /><h3><b>Equipo:</b></h3><br />
                <div className="form_match_row">
                    <label>Elige el Equipo:</label><br />
                    <select name={equipoElegido} required onChange={(e) => {
                        setEquipoElegido(e.target.value);
                        findPlayersTeam(equiposApuntados, e.target.value);
                        findMatchesTeam(tournament.teams, e.target.value);
                    }} >
                        {equiposApuntados.map((team, index) => (
                            <option key={index} value={team.name}>{team.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form_match_row">
                    <label>Equipo ha terminado:</label><br />
                    <select type="number" defaultValue={"1"} name={teamPosition} required onChange={(e) => { setTeamPosition(e.target.value); }}  >
                        <option type="number" className="input_points" value="1" selected required min="0" max="100" >1º</option>
                        <option type="number" className="input_points" value="2" required min="0" max="100" >2º</option>
                        <option type="number" className="input_points" value="3" required min="0" max="100" >3º</option>
                        <option type="number" className="input_points" value="4" required min="0" max="100" >Más del 4º</option>
                    </select>
                </div>
                <div className="form_match_row">
                    <label>Fecha de la Partida:</label><br />
                    <input type="datetime-local" name={datetime} placeholder="fecha" required onChange={(e) => {
                        setDatetime(e.target.value);
                        checkDate(e);
                    }} />
                </div>
                <div className='form_match_row'>
                    <h4>Inicio del Torneo:</h4>
                    <h5 className="text">{fechaComienzo.toLocaleString()}</h5>
                </div>
                <div className="form_match_row">
                    <label>Partida número:</label><br />
                    <label>{numMatchesTeams}:{numPartidas}</label><br />
                </div>

                <br /><h3><b>Jugadores:</b></h3><br />
                <div className="form_match_players">
                    {jugadoresTeam.map((player, index) => (
                        <div className="form_match_row">
                            <h4>{player.id_activision}</h4>
                            <label>Muertes:</label>
                            <input type="number" className="input_pointsBaja" name="" placeholder="Nº de Muertes" required min="0" max="100" onChange={(e) => {
                                sumaMuertes(e.target.value, index)
                            }} />
                        </div>
                    ))}
                </div>
                <div className="form_team_row">
                    <label>Captura de Pantalla:</label><br />
                    <input className="btn_detail_form_input_file" type="file" name="foto" required onChange={(e) => { setImage(e.target.files[0]) }} />
                </div>
                <div className="tournamentdetail_row">
                    <h4>Reglas:</h4><br />
                    <h5 className="text">-Por cada Muerte: {pointsBaja} PUNTOS</h5>
                    <h5 className="text">-Equipo termina 1º :&nbsp;&nbsp;&nbsp;  x{primero} </h5>
                    <h5 className="text">-Equipo termina 2º :&nbsp;&nbsp;&nbsp;  x{segundo} </h5>
                    <h5 className="text">-Equipo termina 3º :&nbsp;&nbsp;&nbsp;  x{tercero} </h5>
                </div>
                <h4 onClick={calculaPuntuacion} className="btn_countPoints">Calcular Puntuación</h4>
                <div className="form_match_totalPoints">
                    <h3>Puntuación Total del Equipo: </h3>
                    <h2>{totalPoints}</h2>
                </div>
                {allowSend && <button type="submit" className="btn_section btn_green">Enviar Resultado</button>}
            </form>

        </div>

    );
}
