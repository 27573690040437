
import { usePromiseTracker } from "react-promise-tracker";
import "./loadingIndicator.css";

export default function LoadingIndicator() {
    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress &&

        <div className="col-3">
            <div className="stage filter-contrast">
                <div className="dot-overtaking"></div>
            </div>
        </div>

    );
}
