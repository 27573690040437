
import "./teamItemDetail.css"
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getTeamByName, updateFotoTeam } from "../../../services/team.service";
import PlayerItem from "../../profiles/item/playerItem"
import { getPlayersByTeam } from "./../../../services/player.service"
import { constants } from "../../utils/const";
import Chat from "../../chats/Chat";

export default function TeamItemDetail({ }) {
    const [fotoEquipo, setFotoEquipo] = useState([]);
    const [foto, setFoto] = useState([]);
    const [nombre, setNombre] = useState([]);
    const [detalles, setDetalles] = useState([]);
    const [jugadores, setJugadores] = useState([]);
    const [isPartner, setIsPartner] = useState(false);
    const [joinCode, setJoinCode] = useState([]);
    const [token, setToken] = useState([]);
    const [email, setEmail] = useState([]);
    const [tieneNick, setTieneNick] = useState(false);

    let { name } = useParams();

    useEffect(() => {
        const nick = JSON.parse(localStorage.getItem("nick"));
        setEmail(JSON.parse(localStorage.getItem("email")));
        setToken(JSON.parse(localStorage.getItem("token")));
        if (email != null) {
            fetchTeam(name)
        }
        if (nick === constants.defaultNick) {
            setTieneNick(false)
        } else {
            setTieneNick(true)
        }
    }, []);

    const fetchTeam = async (name) => {
        const email = JSON.parse(localStorage.getItem("email"));

        try {
            const players = await getPlayersByTeam(name);
            for (let player of players) {
                if (email === player.email) {
                    console.log(email)
                    console.log(player.email)
                    setIsPartner(true);
                }
            }
            setJugadores(players);
            const team = await getTeamByName(name);
            setFotoEquipo(`${team.image}`);
            setNombre(team.name);
            setDetalles(team.details);
            setJoinCode(team.joinCode);
        } catch (e) {
            console.log(e);
        }
    };
    const unirse = async () => {
        {
            !tieneNick
                ? window.location = '/popup/login'
                : window.location = `/popup/joinTeam/${nombre}/a0000`
        }
    }
    const pathTeams = `${process.env.REACT_APP_API_AWS}/teams/profile/${name}`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await updateFotoTeam(foto, token, name);
        if (res.ok) {
            window.location = `/teams/${name}`;
        }
    };
    const copy = async (e) => {
        await navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT}/popup/joinTeam/${nombre}/${joinCode}`);
        e.target.classList.add("copied");
    }
    return (
        <div className="team_detail">
            <div className="list_section">
                <aside className="asideLeft">

                </aside>
                <div className="team_detail_section border_section">
                    {isPartner && <div className="team_detail_nav_copy">
                        <h5>Código de invitación:</h5>
                        <img className="team_detail_nav_copy_link" src="/clipboard.png" alt="copylink" width={20} onClick={copy} onTouchStart={copy} />
                        <h5 className="gold">{joinCode}</h5>
                    </div>}<br />
                    <button className="btn_section btn_green" onClick={unirse}>Unirme</button><br />
                    <h2>{nombre}</h2>
                    <img src={`${fotoEquipo}`} alt={fotoEquipo} className="fotoEquipo" />
                    {isPartner && <form id="formulario" className="player_detail_formulario" action={pathTeams} method='POST' onSubmit={handleSubmit}>
                        <p> Max Size 1MB </p>
                        <input className="btn_detail_form_input_file" type="file" name="foto" onChange={(e) => { setFoto(e.target.files[0]) }} />
                        <input type='submit' value='Actualizar Foto' className="btn_section btn_gold" />
                    </form>}<br />
                    <h4 className="text">{detalles}</h4><br /><br />
                    <div className="team_detail_teams">
                        <h4>&nbsp;&nbsp;-Jugadores:</h4>
                        {jugadores.map((jugador, i) => {
                            return <PlayerItem key={i} position={i + 1} jugador={jugador} />;
                        })}
                    </div>
                </div>
                <aside className="asideRight">
                    <Chat />
                </aside>
            </div>
        </div>

    );
}

