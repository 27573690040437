import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTeams } from "../../../services/team.service";
import TeamItemModel from "../item/TeamItemModel";
import "./teamList.css";
import Chat from "../../chats/Chat";

export default function TeamList() {
  const [teams, setTeams] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const listInnerRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("userAndroid"));
    if (profile != null) {
      if (!lastList && prevPage !== currPage) {
        fetchTeams();
      }
    } else {
      navigate('/popup/google');
    }
  }, [currPage, lastList, prevPage, teams]);

  useEffect(() => {
    setIsLoading(false);
  }, [teams]);

  const fetchTeams = async () => {
    if (isLoading || currPage <= prevPage) return; // Evitar solicitudes duplicadas o regresivas
    setIsLoading(true);
    try {
      const currentPage = currPage; // Almacenar el valor actual de currPage
      const teamsDB = await getTeams(currentPage);
      if (teamsDB.length === 0) {
        setLastList(true);
        return;
      }
      setPrevPage(currentPage);
      orderByWins(teamsDB)
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const orderByWins = (teamsDB) => {
    const allTeams = [...teams, ...teamsDB]
    allTeams.sort((a, b) => b.victorias - a.victorias);
    setTeams(allTeams);
  }
  const onScroll = (e) => {
    if (isLoading) return;
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if ((scrollTop + clientHeight + 1) >= scrollHeight) {
        setCurrPage((prevPage) => prevPage + 1); // Utilizar una función de actualización para asegurarse de obtener el valor más actual de currPage
      }
    }
  };

  return (

    <div className="teams"
      onScroll={onScroll}
      onTouchMove={onScroll}
      ref={listInnerRef}
      style={{ height: "100vh", overflowY: "auto" }}>
      <div className="list_section">
        <aside className="asideLeft">

        </aside>
        <div className="central">
          <div className="list_header_teams">
            <h4>Posición</h4>
            <h4>Equipo</h4>
            <h4>Victorias</h4>
          </div>
          {teams.map((team, i) => {
            return <TeamItemModel key={i} position={i + 1} team={team} />;
          })}
        </div>
        <aside className="asideRight">
          <Chat />
        </aside>
      </div>

    </div>
  );
}
