
const constants = {
    defaultNick : "¡Escribe tu nick!"
}
const variantsMotion = {
    hidden: { opacity: 0, marginBottom: 20},
    visible: ({ delay }) => ({
      opacity: 1,
      marginBottom: 10,
      transition: {
        delay,
        duration: 0.70
      }
    })
  }
export {constants, variantsMotion}