import { trackPromise } from 'react-promise-tracker';
const pathMaps = `${process.env.REACT_APP_API_AWS}/maps`;

const getMaps = async () => {
    try {
      const response = await trackPromise(fetch(pathMaps));
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  const getMapsByName = async (name) => {
    try {
      const response = await trackPromise(fetch(`${pathMaps}/name/${name}`));
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  const getMapsById = async (id) => {
    try {
      const response = await trackPromise(fetch(`${pathMaps}/${id}`));
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
export { getMaps , getMapsByName , getMapsById};
