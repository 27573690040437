import { trackPromise } from 'react-promise-tracker';
const pathTeams = `${process.env.REACT_APP_API_AWS}/teams`;


const getTeams = async (page) => {
  try {
    const response = await trackPromise(fetch(`${pathTeams}/page/${page}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getTeamByName = async (name) => {
  try {
    const response = await trackPromise(fetch(`${pathTeams}/${name}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getTeamsByEmail = async (name) => {
  try {
    const response = await trackPromise(fetch(`${pathTeams}/list/${name}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const saveTeam = async (token, name, details) => {
  const pathTeams = `${process.env.REACT_APP_API_AWS}/teams`;
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    const res = await trackPromise(fetch(`${pathTeams}`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        team: {
          name: name,
          details: details,
          image: ""
        }
      }),
    }));
    return res;
  } catch (err) {
    console.log(err);
  }
};
const checkJoinCodeByName = async (name, joinCode) => {
  try {
    const response = await trackPromise(fetch(`${pathTeams}/check/${name}/${joinCode}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const addPlayerTeam = async (team, token) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    const response = await trackPromise(fetch(`${pathTeams}/${team}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({}),
    }));
    return response;
  } catch (err) {
    console.log(err);
  }
};
const updateFotoTeam = async (image, token, name) => {
  try {
    if (!image) {
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var formdata = new FormData();
    formdata.append("foto", image);

    let res = await trackPromise(fetch(`${pathTeams}/profile/${name}`, {
      headers: myHeaders,
      method: 'POST',
      body: formdata
    }));
    return res;
  } catch (err) {
    console.log(err);
  }
};

export { getTeams, getTeamByName, saveTeam, addPlayerTeam, getTeamsByEmail, checkJoinCodeByName, updateFotoTeam };
