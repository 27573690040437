
import React, { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import "./formjointournament.css"
import { getMapsByName } from '../../../services/map.service';
import { getTournamentById, joinTournament } from './../../../services/tournament.service';
import { getPlayerByToken } from '../../../services/player.service';
import { useParams } from "react-router-dom";
import { constants } from "../../utils/const";
import Chat from "../../chats/Chat";

export default function FormJoinTournament({ }) {
    const [color, setColor] = useState([]);
    const [finInscripcion, setFinInscripcion] = useState([]);
    const [fechaComienzo, setFechaComienzo] = useState([]);
    const [mapName, setMapName] = useState([]);
    const [equipoElegido, setEquipoElegido] = useState([]);
    const [token, setToken] = useState([]);
    const [fotoMapa, setFotoMapa] = useState([]);
    const [nombre, setNombre] = useState([]);
    const [reglas, setReglas] = useState([]);
    const [details, setDetails] = useState([]);
    const [organizadores, setOrganizadores] = useState([]);
    const [maxteams, setMaxteams] = useState([]);
    const [teamsJoints, setEquiposApuntados] = useState([]);
    const [teamsPlayer, setTeamsPlayer] = useState([]);
    const [tieneNick, setTieneNick] = useState(false);
    const [colorFecha, setColorFecha] = useState([]);
    const [colorFechaInicio, setColorFechaInicio] = useState([]);
    const [email, setEmail] = useState([]);

    let { id } = useParams();

    useEffect(() => {
        setEmail(JSON.parse(localStorage.getItem("email")));
        const tokenLS = JSON.parse(localStorage.getItem("token"));
        setToken(tokenLS);
        fetchTournament(id)
        fetchTeamsPlayer(tokenLS)
    }, []);
    const fetchTeamsPlayer = async (tokenLS) => {
        try {
            const player = await getPlayerByToken(tokenLS);
            setTeamsPlayer(player.teams)
            setEquipoElegido(player.teams[0])
            if (player.id_activision === constants.defaultNick) {
                setTieneNick(false)
            } else {
                setTieneNick(true)
            }
        } catch (e) {
            console.log(e);
        }
    };
    const fetchTournament = async (id) => {
        try {
            const torneoDB = await getTournamentById(id);
            //console.log(torneoDB);
            await fetchMaps(torneoDB.mapa)
            setMaxteams(torneoDB.maxteams)
            setEquiposApuntados(torneoDB.teams.length)
            setNombre(torneoDB.nombre)
            setFinInscripcion(new Date(torneoDB.finInscripcion).toLocaleString())
            setFechaComienzo(new Date(torneoDB.fechaComienzo).toLocaleString())
            setReglas(torneoDB.reglas)
            setDetails(torneoDB.details)
            setOrganizadores(torneoDB.organizadores)
            setFotoMapa(`/${torneoDB.mapa}.jpg`);
            colorPosition(torneoDB.teams, torneoDB.maxteams);
            checkColorFecha(torneoDB.finInscripcion);
            checkColorFechaInicio(torneoDB.fechaComienzo)
        } catch (e) {
            console.log(e);
        }
    };
    const fetchMaps = async (name) => {
        try {
            const map = await getMapsByName(name);
            //console.log(map);
            setMapName(map.name)
        } catch (e) {
            console.log(e);
        }
    };
    const colorPosition = (apuntados, maximos) => {
        if ((apuntados.length >= 0)) {
            setColor("#0AE800"); //Verde
        }
        if ((apuntados.length >= maximos * 0.5)) {
            setColor("#0074E8"); //Azul
        }
        if ((apuntados.length >= maximos * 0.75)) {
            setColor("#E81C00"); //Rojo
        }
    }
    const checkColorFecha = (fechaInscripcion) => {
        const actual = new Date();
        const fechaElegida = new Date(fechaInscripcion);
        if (fechaElegida.getTime() <= actual.getTime()) {
            setColorFecha("#E81C00"); //Rojo
        } else {
            setColorFecha("#0AE800"); //Verde
        }
    }
    const checkColorFechaInicio = (fechaComienzo) => {
        const actual = new Date();
        const fechaElegida = new Date(fechaComienzo);
        if (fechaElegida.getTime() <= actual.getTime()) {
            setColorFechaInicio("#E81C00"); //Rojo
        } else {
            setColorFechaInicio("#0AE800"); //Verde
        }
    }

    const pathTournaments = `${process.env.REACT_APP_API_AWS}/tournaments/${id}`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const team = {
                name: equipoElegido,
                player: email
            }
            const res = await joinTournament(token, id, team);
            if (res.ok) {
                window.location = `/tournaments/${id}`
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="form_jointournament">
            <div className="list_section">
                <aside className="asideLeft">

                </aside>
                <div className="form_jointournament_container border_section">
                    <form action={pathTournaments} className="form_jointournament" onSubmit={handleSubmit} >
                        <div className="jointournament_detail_container">
                            <img src={fotoMapa} alt={fotoMapa} className="fotomapa" />
                            <h4>{mapName}</h4><br />
                            <h3 className="permanent gold">{nombre}</h3>
                        </div>
                        <div className='form_jointournament_row'>
                            <h4>Inscripción hasta:</h4>
                            <h5 className="text" style={{ color: colorFecha }}>{finInscripcion}</h5>
                        </div>
                        <div className='form_jointournament_row'>
                            <h4>Fecha Inicio Torneo:</h4>
                            <h5 className="text" style={{ color: colorFecha }}>{fechaComienzo}</h5>
                        </div>
                        <div className="form_jointournament_row">
                            <h4>Equipos:</h4>
                            <h3 style={{ color: color }}><b>{teamsJoints}:{maxteams}</b></h3>
                        </div>
                        <div className="form_jointournament_row">
                            <label>Elige tu equipo:</label><br />
                            <select name={equipoElegido} required onChange={(e) => setEquipoElegido(e.target.value)} >
                                {teamsPlayer.map((name, index) => (
                                    <option key={index} value={name}>{name}</option>
                                ))}
                            </select>
                        </div><br />
                        {!tieneNick
                            ? <Link to={`/popup/login`} ><button type="submit" className="btn_section btn_green">Aceptar Inscripción</button></Link>
                            : <button type="submit" className="btn_section btn_gold">Aceptar Inscripción</button>
                        }<br />
                        <div className="jointournamentdetail_row">
                            <h4>Organizadores:</h4><br />
                            {organizadores.map((organizador, i) => {
                                return <h5 className="text">{organizador.nick}</h5>
                            })}
                        </div><br />
                        <div className="jointournamentdetail_row">
                            <h4>Reglas:</h4><br />
                            <h5 className="text">-Por cada Muerte: {reglas.pointsBaja} PUNTOS</h5>
                            <h5 className="text">-Equipo termina 1º:&nbsp;&nbsp;&nbsp; x {reglas.primero} </h5>
                            <h5 className="text">-Equipo termina 2º:&nbsp;&nbsp;&nbsp;  x {reglas.segundo} </h5>
                            <h5 className="text">-Equipo termina 3º:&nbsp;&nbsp;&nbsp;  x {reglas.tercero} </h5>
                        </div><br />
                        <div className="jointournamentdetail_row">
                            <h4>Detalles del Torneo:</h4><br />
                            <h5 className="text">-{details}</h5>
                        </div>
                    </form>
                </div>
                <aside className="asideRight">
                    <Chat />
                </aside>
            </div>
        </div>

    );
}
