
import React, { useEffect, useState } from "react";
import "./chat.css";
import io from 'socket.io-client';
import ChatItem from "./ChatItem";

const pathChatSocket = `${process.env.REACT_APP_API_AWS_SOCKET}`;
const socket = io.connect(pathChatSocket);

export default function Chat({ }) {

    const [nick, setNick] = useState([]);
    const [textoEscrito, setTextoEscrito] = useState([]);
    const [textoChat, setTextoChat] = useState([]);

    useEffect(() => {
        const nickLS = localStorage.getItem("nick");
        if (nickLS != null)
            setNick(JSON.parse(nickLS));

        socket.on('envia mensaje', (msg, name) => {
            const mensaje = [{
                nick: name,
                message: msg
            }];
            setTextoChat([...textoChat, ...mensaje]);
        });
    }, [textoChat]);

    const enviarTexto = () => {
        socket.emit('envia mensaje', textoEscrito, nick);
        setTextoEscrito("");
        document.getElementById("chat_detail_input").value = "";
    }

    return (
        <div className="chat_detail border_aside">
            <div className="chat_detail_container">
                <div>
                    <br /><br />
                    {textoChat.map((parrafo, i) => {
                        return <div key={i}>
                            <ChatItem key={i} parrafo={parrafo} />
                        </div>
                    })}
                    <br />
                </div>
                <div className="chat_detail_inferior">
                    <input name="chat_detail_input" id="chat_detail_input" className="chat_detail_input" onChange={(e) => { setTextoEscrito(e.target.value); }} ></input>
                    <button id="enviar" onClick={enviarTexto} className="btn_section btn_green"> Enviar</button>
                </div>
                <a className="atribuciones" href="https://www.freepik.es/foto-gratis/superficie-metal-rayado-estilo-grunge-oscuro_10167160.htm#query=fondo%20oscuro&position=12&from_view=keyword">Imagen de kjpargeter</a>
            </div>
        </div>
    );
}

