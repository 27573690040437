import { Outlet } from "react-router-dom";
import Nav from "./nav/Nav";
import LoadingIndicator from "../components/utils/LoadingIndicator";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Footer from "./footer/Footer"
import "./App.css";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";
import Politica from "./politica/Politica";

function App() {

  const clientId = '411948844835-mvcjutcel8v4ottia5p49jt47rutm0al.apps.googleusercontent.com';

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div>
        <Nav />
        <main>
          <Politica />
          <div className='loading'>
            <LoadingIndicator />
          </div>
          <Outlet />
        </main>
        <Footer />
        <CookieConsent
          debug={true}
          location="bottom"
          buttonText="Entendido"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B", textAlign: "center", zIndex:"2000" }}
          buttonStyle={{ background: "#EFB810", color: "black", fontSize: "13px" }}
          expires={150}
        ><p>Este sitio web utiliza Cookies para aumentar la experiencia de usuario.</p><br />
          <Link to="/politicaprivacidad"><h5>-Política de Privacidad-</h5></Link>
        </CookieConsent>
      </div>
    </GoogleOAuthProvider>

  );
}

export default App;
