
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";

export default function Footer() {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos < currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);


  return (
    <footer className={visible ? 'footer' : 'footer hidden'}>
      <div className="footer-details">
        <div className='footer-links'>
          <Link to="/politicaprivacidad"><h4>Contacto</h4></Link>
          <Link to="/politicaprivacidad"><h4>Política de Privacidad</h4></Link>
        </div>
      </div>
    </footer>
  );
}
