import { trackPromise } from 'react-promise-tracker';
const pathResults = `${process.env.REACT_APP_API_AWS}/matches/`;

const getMatches = async () => {
  try {
    const response = await trackPromise(fetch(pathResults));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getMatchesByTournament = async (id) => {
  try {
    const response = await trackPromise(fetch(`${pathResults}/${id}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const saveMatch = async (token, equipoElegido, totalPoints, datetime) => {
  const pathMatches = `${process.env.REACT_APP_API_AWS}/matches`;
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    let res = await trackPromise(fetch(`${pathMatches}`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        match: {
          team: equipoElegido,
          points: totalPoints,
          datetime: datetime
        }
      }),
    }));
    return res;
  } catch (err) {
    console.log(err);
  }
};
const addMatchTournament = async (token, idTorneo, idMatch, equipoElegido, totalPoints, datetime) => {
  const pathMatches = `${process.env.REACT_APP_API_AWS}/matches`;
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    let res = await trackPromise(fetch(`${pathMatches}`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
            match: {
                id: idMatch,
                team: equipoElegido,
                points: totalPoints,
                datetime: datetime
            },
            idTorneo: idTorneo
        }),
    }));
    return res;
  } catch (err) {
    console.log(err);
  }
};
const updateFotoMatch = async (image, token, idMatch) => {
  const pathMatches = `${process.env.REACT_APP_API_AWS}/matches/foto/${idMatch}`;
  try {
    if (!image) {
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var formdata = new FormData();
    formdata.append("foto", image);

    let res = await trackPromise(fetch(`${pathMatches}`, {
      headers: myHeaders,
      method: 'POST',
      body: formdata
    }));
    return res;
  } catch (err) {
    console.log(err);
  }
};

export { getMatches, getMatchesByTournament, saveMatch, addMatchTournament, updateFotoMatch };
