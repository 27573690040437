import "./team.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";


export default function TeamItem({ team, position ,teamTournament}) {
  const [color, setColor] = useState([]);

  useEffect(() => {
    colorPosition();
  }, []);

  const colorPosition = () => {
    if(position === 1){
      setColor("#0AE800");
    }else if(position === 2){
      setColor("#0074E8");
    }else if(position === 3){
      setColor("#E81C00");
    }else {
      setColor("white");
    }
  }

  return (
    <Link to={`/teams/${team.name}`}>
      <div className="team_item">
        <h1 style={{color: color}}>{position}º</h1>
        <div className="team_item_logo">
          <img src={`${team.image}`} alt={team.name} />
          <div>
            <h3>{team.name}</h3>
          </div>
        </div>
        <div className="points">
          <h3 style={{color: color}}><b>{teamTournament.points}</b></h3>
          <p><b>Puntos</b></p>
        </div>
      </div>
    </Link>
  );
}

