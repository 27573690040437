import { trackPromise } from 'react-promise-tracker';
const pathReport = `${process.env.REACT_APP_API_AWS}/reports`;

const addReport = async (report,token) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    try {
      const response = await trackPromise(fetch(pathReport, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(report)
      }));
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

export { addReport };
