import "./teamitemmodel.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { variantsMotion } from "../../utils/const";
import { motion } from "framer-motion";

export default function TeamItemModel({ team, position }) {
  const [color, setColor] = useState([]);

  useEffect(() => {
    colorPosition();
  }, []);

  const colorPosition = () => {
    if (position === 1) {
      setColor("#0AE800");  //Verde
    } else if (position === 2) {
      setColor("#FFDC00"); //Amarillo
    } else if (position === 3) {
      setColor("#EC4B00");  //Naranja
    } else {
      setColor("white");
    }
  }

  return (
    <motion.div
      custom={{ delay: (position * 0.05) }}
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={variantsMotion}
      layoutId={position}
    >
      <Link to={`/teams/${team.name}`}>
        <div className="team_item_model">
          <h1 style={{ color: color }}>{position}º</h1>
          <div className="team_item_model_center">
            <img className="team_item_model_logo" src={`${team.image}`} alt={team.name} />
            <div>
              <h3>{team.name}</h3>
            </div>
          </div>
          <div className="wins">
            <img className="team_item_model_victoria" src={`/medallon.png`} alt="" />
            <h2 style={{ color: color }}><b>{team.victorias}</b></h2>
          </div>
        </div>
      </Link>
    </motion.div>
  );
}

