import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMatchesByTournament } from "../../../services/match.service";
import MatchItem from "../item/MatchItem";

export default function MatchList() {
  const [teams, setTeams] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("userAndroid"));
    if (profile != null) {
      fetchResults();
    } else {
      alert("Inicia sesión!");
    }
  }, []);

  const fetchResults = async () => {
    try {
      const result = await getMatchesByTournament(id)
      setTeams(result.teams);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <section className="matches">
      <div className="list_section">
        <aside className="matches_aside">

        </aside>
        <div>
          <div className="list_header_matches">
            <h4>Equipo</h4>
            <h4>Puntuación</h4>
            <h4>Fecha</h4>
            <h4>Captura</h4>
          </div>
          {teams.map((team, i) => {
            return <div key={i}>
              {team.matches.map((match, i) => {
                return <MatchItem key={i} position={i + 1} team={team.name} match={match} />
              })}
            </div>
          })}
        </div>
        <aside className="matches_aside">

        </aside>
      </div>

    </section>
  );
}
