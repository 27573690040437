import { GoogleLogin } from '@react-oauth/google';
import { gapi } from 'gapi-script';
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addPlayer } from "../../services/player.service";
import { constants } from "../utils/const"
import "./nav.css";

export default function Nav() {
  const [authToken, setAuthToken] = useState(false);
  const [tieneNick, setTieneNick] = useState(false);
  const [isLoged, setIsLoged] = useState(false);
  const [foto, setFoto] = useState([0]);
  const [idActivision, setIdActivision] = useState([0]);
  const [idPlayer, setIdPlayer] = useState([0]);
  const clientId = '411948844835-mvcjutcel8v4ottia5p49jt47rutm0al.apps.googleusercontent.com';

  const navigate = useNavigate();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
    const user = JSON.parse(localStorage.getItem("user"));
    const userAndroid = JSON.parse(localStorage.getItem("userAndroid"));
    if (user != null) {
      const data = {
        profile: user
      }
      fetchPlayers(data);
    }
    if (userAndroid != null) {
      fetchPlayers(userAndroid);
    }
  }, []);

  const fetchPlayers = async (data) => {

    try {
      let player = await addPlayer(data);
      //console.log(player);
      if (player) {
        setIsLoged(true);
        if (player.email) {
          localStorage.setItem("email", JSON.stringify(player.email));
        }
        if (player.id_activision) {
          localStorage.setItem("nick", JSON.stringify(player.id_activision));
          setIdActivision(player.id_activision);
        }
        if (player.foto) {
          setFoto(`${player.foto}`)
        } else {
          setFoto("amigo_nuevo.png");
        }
        if (player._id) {
          setIdPlayer(player._id);
        }
        if (player.token === undefined) {
          localStorage.setItem("token", JSON.stringify(null))
          setAuthToken(false)
        } else {
          localStorage.setItem("token", JSON.stringify(player.token))
          setAuthToken(true)
        }
        if (player.id_activision === constants.defaultNick) {
          setTieneNick(false)
        } else {
          setTieneNick(true)
        }
      }
    } catch (e) {
      localStorage.setItem("token", null)
      setAuthToken(false)
      //console.log(e);
    }
  };

  const onSuccess = async (res) => {
    if (res) {
      localStorage.setItem("user", JSON.stringify(res.credential));
      const data = {
        profile: res.credential
      }
      fetchPlayers(data);
    }
    //console.log(res)
  };
  const onFailure = (err) => {
    setIsLoged(false);
    console.log('error:', err);
  };
  const login = () => {
    navigate('/popup/google');
  }

  return (
    <nav>
      <div className="nav-details">
        {isLoged && <div className='nav-superior'>
          <div>
            <Link to={`/`}><img className='nav-logo' src={`${process.env.REACT_APP_FRONT}/torneosWarzone.png`} alt="" /></Link>
            {!tieneNick
              ? <Link to={`/popup/login`} ><img className='nav-icon' src={foto} alt="perfil" /></Link>
              : <Link to={`/profile/${idPlayer}`}><img className='nav-icon' src={foto} alt="perfil" /></Link>
            }
          </div>
          <Link to="/popup/login"><h4 className='text'>{idActivision}</h4></Link>
          <div className='nav-superior-chat'>
            {!tieneNick
              ? <Link to={`/popup/login`} ><img className='nav-icon' src={`${process.env.REACT_APP_FRONT}/chat.png`} alt="chat" /></Link>
              : <Link to={`/chat`}><img className='nav-icon' src={`${process.env.REACT_APP_FRONT}/chat.png`} alt="chat" /></Link>
            }
          </div>
        </div>}
        <div className='nav-central'>
          <button className='btn_addTorneo btn_goldLogin' onClick={login}>Iniciar Sesión</button>
          {!tieneNick
            ? <Link to={`/popup/login`} ><button className='btn_addTorneo'>Crear Equipo</button></Link>
            : <Link to="/newTeam"><button className='btn_addTorneo'>Crear Equipo</button></Link>
          }
          {!tieneNick
            ? <Link to={`/popup/login`} ><button className='btn_addTorneo'>Crear Torneo</button></Link>
            : <Link to="/newTournament"><button className='btn_addTorneo'>Crear Torneo</button></Link>
          }

        </div>
        <div className='nav-links'>
          <Link to="/tournaments"><h4 className='permanent'>TORNEOS</h4></Link>
          <Link to="/teams"><h4 className='permanent'>EQUIPOS</h4></Link>
          {!tieneNick
            ? <Link to={`/popup/login`} ><h4 className='permanent'>NUEVO</h4></Link>
            : <Link to="/newForm"><h4 className='permanent'>NUEVO</h4></Link>
          }

        </div>
      </div>
    </nav>
  );
}
